import { render, staticRenderFns } from "./TouribookCustomerData.vue?vue&type=template&id=15993231&scoped=true&lang=pug"
import script from "./TouribookCustomerData.vue?vue&type=script&lang=js"
export * from "./TouribookCustomerData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15993231",
  null
  
)

export default component.exports